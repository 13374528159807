import React from "react";

const Topbar = () => {
  return (
    <div className="container-fluid bg-warning px-5 d-none d-lg-block">
      <div className="row gx-0">
        <div className="col-lg-8  text-center text-lg-start mb-2 mb-lg-0">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: 45 }}
          >
            <small className="me-3">
              <i className="fa fa-map-marker-alt me-2" />
              Unit-3, 2A Beaconsfield St, Newcastle upon Tyne NE4 5JN
            </small>
            <small className="me-3 ">
              <i className="fa fa-phone-alt me-2" />
              0191 691 8791
            </small>
            <small className="">
              <i className="fa fa-envelope-open me-2" />
              info@taxiaccounting.co.uk
            </small>
          </div>
        </div>
        <div className="col-lg-4 text-center text-lg-end">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: 45 }}
          >
            <a
              className="btn btn-sm btn-outline-dark btn-sm-square rounded-circle me-2"
              href=""
            >
              <i className="bi bi-twitter"></i>
            </a>
            <a
              className="btn btn-sm btn-outline-dark btn-sm-square rounded-circle me-2"
              href="https://www.facebook.com/taxiaccounting/"
              target="_blank"
            >
              <i className="bi bi-facebook"></i>
            </a>
            <a
              className="btn btn-sm btn-outline-dark btn-sm-square rounded-circle me-2"
              href=""
            >
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Topbar;
