import React from "react";
import { Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
import { Link } from "react-router-dom";


const HeroCarousel = () => {
  return (
    <div className="container-fluid position-relative px-0 pt-16 pt-md-0">
        <Carousel fade>
            <Carousel.Item>
                <img className="w-100" src="/assets/img/carousel-1.jpg" alt="Image"/>
                <Carousel.Caption>
                    <div className="p-3 mx-auto h-100 d-flex flex-column justify-content-center item-align-center" style={{maxWidth: "900px"}}>
                        {/* <h5 className="text-white text-uppercase mb-3 animated slideInDown">Self Assessment</h5> */}
                        <h1 className="text-white  mb-3 animated slideInDown">Self-Assessment</h1>
                        <h1 className="display-1 text-white mb-md-4 animated zoomIn">Accounting Solution</h1>
                        <div>
                            <Link to='/cal'>
                                <div  className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Tax Calculator</div>
                            </Link>
                        
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            
            <Carousel.Item>
                <img className="w-100" src="/assets/img/carousel-2.jpg" alt="Image"/>
                <Carousel.Caption>
                    <div className="p-3 mx-auto h-100 d-flex flex-column justify-content-center item-align-center" style={{maxWidth: "900px"}}>
                        <h1 className="text-white  mb-3 animated slideInDown">Self-Assessment</h1>
                        <h1 className="display-1 text-white mb-md-4 animated zoomIn">Accounting Solution</h1>
                        <div>
                            <Link to="/cal">
                                <div className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Tax Calculator</div>
                            </Link>
                            
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

        </Carousel>
    </div>
  );
}

export default HeroCarousel;
