import React from 'react';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

// Routes
import taxiRoute from './TaxiRouter';

// Components
import RouteWithSubRoutes from './RouteWithSubRoutes';
import NavBar from '../component/Navbar';
import Topbar from '../component/Topbar';
import AboutUs from '../component/About';
import Calculator from '../pages/calculator';
import Home from '../pages/Home';
// import Login from '../pages/Login';

const MasterRoute = () => {
    return (
        <Router>
            <Topbar />
            <div className='position-relative'>
                <NavBar/>
            </div>
            <Routes>
                <Route path="/" exact element={<Home/>} />
                <Route path="/about" element={<AboutUs/>} />
                <Route path="/cal" element={<Calculator/>} />
            </Routes>
        </Router>
    
    )
}

export default MasterRoute;
