import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { CloseButton } from "react-bootstrap";
const TextResult = (props) => {
  const { show, data, setShow } = props;

  const closebtn = (event) => {
    setShow(false);
    window.location.reload();
  };
        
  const netIncome = data.payment_amount - data.expense_amount;
  const taxableAmount = netIncome - 12570;
  const estimateTax = taxableAmount * (20 / 100);
  const class4 = netIncome * (10.25 / 100);
  const class4new = class4?.toFixed(2);
  const class5 = netIncome * (13.50 / 100);
  const class5new = class5?.toFixed(2);

  return (
    <div id="tax-output" className={`${show ? "" : "d-none"} mt-5 p-5`}>
      <div className="d-flex justify-content-end py-4">
        <CloseButton onClick={(e) => closebtn(e)} />
      </div>

      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Net Income</td>
            <td>£ {netIncome === 0 ? "0.00" : netIncome}</td>
          </tr>
          <tr>
            <td>Personal Allowance</td>
            <td>£ 12570.00</td>
          </tr>
          <tr>
            <td>Taxable Amount</td>
            <td>£ {taxableAmount < 0 ? "0.00" : taxableAmount}</td>
          </tr>
          <tr>
            <td>Estimate Tax (% 20)</td>
            <td>£ {estimateTax < 0 ? "0.00" : estimateTax}</td>
          </tr>
          <tr>
            <td>Class 2 NI contribution (Net Income &gt; £ 6725.00)</td>
            <td>£ {netIncome > 6725 ? "159" : "0.00"}</td>
          </tr>
          <tr>
            <td>Class 4 (Net Income &gt; £ 9881.00) NI contribution = % 10.25</td>
            <td>£ {9881 < netIncome && netIncome < 50000 ? class4new : ""}{netIncome>50000 ? class5new : ""}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
export default TextResult;
