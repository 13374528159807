import React from "react";

const PriceCard = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" id="pricing">
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Pricing Plans</h5>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
            <div className="bg-light rounded">
              <div className="border-bottom py-4 px-5 mb-4">
                <h4 className="text-primary mb-1">Flexi</h4>
              </div>
              <div className="p-5 pt-0">
                <h1 className="display-5 mb-3">
                  <small
                    className="align-top"
                  >
                    £
                  </small>
                   60.00
                  <small
                    className="align-bottom"
                  >
                  </small>
                </h1>
                <div className="d-flex justify-content-between mb-3">
                  <span>Business Advice</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span>Add weekly income</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span>Add weekly expense</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>12 months free financial statements</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>Guide to Self-Assessment registration</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>Send statement to your Accountant</span>
                  <i className="bi bi-check"></i>
                </div>
                <a href="https://taxiaccounting.co.uk/backend/checkout/60" target="_blank" className="btn btn-primary py-2 px-4 mt-4">
                  Pay Now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
            <div
              className="bg-white rounded shadow position-relative"
              style={{ zIndex: 1 }}
            >
              <div className="border-bottom py-4 px-5 mb-4">
                <h4 className="text-primary mb-1">Flexi Plus  <span className="border border-primary rounded px-1 bg-primary text-white">Most Popular</span></h4>
                
              </div>
              <div className="p-5 pt-0">
                <h1 className="display-5 mb-3">
                  <small
                    className="align-top"
                  >
                    £
                  </small>
                  100.00
                  <small
                    className="align-bottom"
                  >
                  </small>
                </h1>
                <div className="d-flex justify-content-between mb-3">
                  <span>Business Advice</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span>Allocated online Accountant</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span>Weekly income</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>Weekly expense</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>12 months Financial Statements</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>HMRC Tax Return with Certified chartered Accountant</span>
                  <i className="bi bi-check"></i>
                </div>
                <a href="https://taxiaccounting.co.uk/backend/checkout/100" target="_blank" className="btn btn-primary py-2 px-4 mt-4">
                  Pay Now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
            <div className="bg-light rounded">
              <div className="border-bottom py-4 px-5 mb-4">
                <h4 className="text-primary mb-1">Premium</h4>
              </div>
              <div className="p-5 pt-0">
                <h1 className="display-5 mb-3">
                  <small
                    className="align-top"
                  >
                    £
                  </small>
                  140.00
                  <small
                    className="align-bottom"
                  > 
                  </small>
                </h1>
                <div className="d-flex justify-content-between mb-3">
                  <span>Online Consultancy</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span>Allocated Physical Accountant</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span>Weekly income</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>Weekly expense</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>12 months Financial Statements</span>
                  <i className="bi bi-check"></i>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>HMRC Tax Return with certified Accountant</span>
                  <i className="bi bi-check"></i>
                </div>
                <a href="https://taxiaccounting.co.uk/backend/checkout/140" target="_blank" className="btn btn-primary py-2 px-4 mt-4">
                  Pay Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PriceCard;
