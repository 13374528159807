import React from "react";
import { Button, Nav, Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <Navbar
      className="px-md-3 px-lg-5 py-0 w-100"
      variant="dark"
      expand="lg"
      top="sticky"
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img src="/assets/img/logo.png" alt="Image" width="70px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="py-4 py-lg-0" id="basic-navbar-nav">
          <Nav className=" navbar-nav ms-auto py-0">
            <Nav.Link className="nav-item nav-link" href="/">
              Home
            </Nav.Link>
            <Nav.Link href="#about" className="nav-item nav-link">
              About
            </Nav.Link>
            <Nav.Link className="nav-item nav-link" href="#pricing">
              Services
            </Nav.Link>
            <Nav.Link className="nav-item nav-link" href="#feature">
              Features
            </Nav.Link>
            <Nav.Link className="nav-item nav-link" href="#contact">
              Contact
            </Nav.Link>
            <Nav.Link
              className="nav-item nav-link"
              href="https://taxiaccounting.co.uk/backend/"
              target="_blank"
            >
              Login
            </Nav.Link>
            <Nav.Link
              className="nav-item nav-link"
              href="https://taxiaccounting.co.uk/backend/register"
              target="_blank"
            >
              Register
            </Nav.Link>
          </Nav>
          {/* <a href="https://taxiaccounting.co.uk/backend/" target="_blank">
            <Button variant="primary" className="mx-4">
              LOGIN
            </Button>
          </a>
          <a
            href="https://taxiaccounting.co.uk/backend/register"
            target="_blank"
          >
            <Button variant="primary">REGISTER</Button>
          </a> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavBar;
