import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MasterRoute from "./router/MasterRoute";
import "../src/main.css";
import "./main.js";
import Home from './pages/Home'

function App() {
  return (
    <div>
      <MasterRoute>

      </MasterRoute>
    </div>
  );
}

export default App;
