import React from "react";
import NavBar from "../component/Navbar";
import HeroCarousel from "../component/Carousel";
import AboutUs from "../component/About";
import PriceCard from "../component/Prices";
import FeaturesList from "../component/Features";
import ContactUs from "../component/Contact";
import Footer from "../component/Footer";

const Home =()=>{
    return(
        <div>
            <HeroCarousel />
            <AboutUs />
            <PriceCard />
            <FeaturesList />
            <ContactUs />
            <Footer/>
        </div>
    );
}
export default Home;