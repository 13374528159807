import React from "react";
import TaxInput from "../component/taxcalculator/TaxInput";

const Calculator =()=>{
    return(
        <div>
            <div className="calculator-banner p-10 mb-5" >
                <img  src="/assets/accounting.jpg" className=" banner-img w-100" style={{maxHeight : "450px"}}/>
            </div>
            <TaxInput/>
        </div>
    );
}
export default Calculator;