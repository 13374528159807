import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col, Container, Button } from "react-bootstrap";
import TaxResult from "./TaxResult";
import "./TaxInput.css";
import Footer from "../Footer";
const TaxInput = () => {
  const [show, setShow] = useState(false);
  const [ data, setData ] = useState({
    payment_option: "Select your payment type",
    payment_amount: "",
    expense_option: "Select your expense type",
    expense_amount: "",
  });

  const calculate = (event) => {
    setShow(true);

    setInterval(function () {
      var element = document.getElementById("tax-output");
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, 1000);
  };

  const handleSubmit = (event) => {
    const value = event.target.value;
    event.preventDefault();
    setData({
        ...data,
        [event.target.name]: event.target.value,
    });
    console.log("data", data);
  };

  const handleChange = (e)=> {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  }



  return (
    <React.Fragment>
      <Container>
        <Row>
          <div
            className="section-title text-center position-relative pb-3 mb-3 mx-auto"
            style={{ maxWidth: 600 }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              TAX CALCULATION 2022/2023
            </h5>
          </div>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row className="mt-4">
            <Col md={6}>
              <div className="p-4 border border-primary">
                <h5 className="text-center mt-2 mb-4 text-secondary">
                Self employed Income
                </h5>
                <Row>
                  <Col md={6}>
                    <p className="text-center">Payment Option</p>
                    <Form.Select 
                    id="disabledSelect" 
                    onChange={handleChange}
                    name="payment_option"
                    value={data.payment_option}>
                      <option>Total Income</option>
                    </Form.Select>
                  </Col>

                  <Col md={6}>
                    <p className="text-center"> Amount</p>
                    <div
                      className="d-flex align-items-center ms-auto "
                      style={{ maxWidth: "180px" }}
                    >
                      <span className="me-3">£</span>
                      <Form.Control 
                      id="card" 
                      placeholder="" 
                      value={data.payment_amount}
                      name="payment_amount"
                      onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-4 border border-primary">
                <h5 className="text-center mt-2 mb-4 text-secondary">
                Self employed Expense
                </h5>
                <Row>
                  <Col md={6}>
                    <p className="text-center">Expense Option</p>
                    <Form.Select 
                    id="disabledSelect"
                    onChange={handleChange}
                    name="expense_option"
                    value={data.expense_option}>
                      <option>Total expense</option>
                      
                    </Form.Select>
                  </Col>

                  <Col md={6}>
                    <p className="text-center"> Amount</p>
                    <div
                      className="d-flex align-items-center ms-auto "
                      style={{ maxWidth: "180px" }}
                    >
                      <span className="me-3">£</span>
                      <Form.Control 
                      id="card" 
                      placeholder="" 
                      name="expense_amount"
                      value={data.expense_amount}
                      onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <div className="text-center">
              <Button
                onClick={(e) => calculate(e)}
                className="submit-btn text-center mt-5"
                type="submit"
              >
                Submit
              </Button>
             
            </div>
          </Row>
        </Form>
        <TaxResult show={show} setShow={setShow} data={data} />
      </Container>
      <Footer />
    </React.Fragment>
  );
};
export default TaxInput;
