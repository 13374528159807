import React from "react";

const Footer = () => {
  return (
    <>
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-6 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="index.html" className="navbar-brand">
                  <h1 className="m-0 text-white">
                    <i className="fa fa-user-tie me-2" />
                    Our services
                  </h1>
                </a>
                <p className="mt-3 mb-4">
                  Taxi Accounting is a time-consuming system to reduce your time
                  and afford to make your taxi business as simple as you
                  understand After joining win this system, you are no longer.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-12 col-md-12 pt-5 ps-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">
                      Unit-3, 2A Beaconsfield St, Newcastle upon Tyne NE4 5JN
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <p className="mb-0">info@taxiaccounting.co.uk</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2" />
                    <p className="mb-0">0191 691 8791</p>
                  </div>
                  <div className="d-flex mt-4">
                    <a
                      className="btn btn-primary btn-square me-2"
                      href="https://www.facebook.com/taxiaccounting/"
                    >
                      <i className="bi bi-facebook fw-normal" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-dark bg-warning"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 75 }}
              >
                <p className="mb-0">
                  ©{" "}
                  <a
                    className="text-dark border-bottom"
                    href="https://taxiaccounting.co.uk/"
                  >
                    Taxi Accounting
                  </a>
                  .All Rights Reserved (Version 0.06)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
