import React from "react";

const ContactUs = () => {
  return (
    <div
      className="container-fluid py-5 wow fadeInUp"
      data-wow-delay="0.1s"
      id="contact"
    >
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
          <h1 className="mb-0">Have you any inquiry</h1>
        </div>
        <div className="row g-5 mb-5">
          <div className="col-lg-4">
            <div
              className="d-flex align-items-center wow fadeIn"
              data-wow-delay="0.1s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: 60, height: 60 }}
              >
                <i class="bi bi-telephone-fill text-white"></i>
              </div>
              <a href="tel:01916918791" style={{ textDecoration: "none" }}>
                <div className="ps-4">
                  <h5 className="mb-2" style={{ color: "#212529" }}>
                    Call to ask any question
                  </h5>
                  <h6 className="text-primary mb-0">01916918791</h6>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="d-flex align-items-center wow fadeIn"
              data-wow-delay="0.4s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: 60, height: 60 }}
              >
                <i class="bi bi-envelope-open-fill text-white"></i>
              </div>
              <a href="mailto:info@taxiaccounting.co.uk" style={{ textDecoration: "none" }}>
                <div className="ps-4">
                  <h5 className="mb-2" style={{ color: "#212529" }}>Email to ask any question</h5>
                  <h6 className="text-primary mb-0">
                    info@taxiaccounting.co.uk
                  </h6>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="d-flex align-items-center wow fadeIn"
              data-wow-delay="0.8s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: 60, height: 60 }}
              >
                <i class="bi bi-geo-alt-fill text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Visit our office</h5>
                <h6 className="text-primary mb-0">
                  Unit-3, 2A Beaconsfield St, Newcastle upon Tyne NE4 5JN
                </h6>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row g-5">
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
            <form>
              <div className="row g-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control border-0 bg-light px-4"
                    placeholder="Your Name"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control border-0 bg-light px-4"
                    placeholder="Your Email"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control border-0 bg-light px-4"
                    placeholder="Subject"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control border-0 bg-light px-4 py-3"
                    rows={4}
                    placeholder="Message"
                    defaultValue={""}
                  />
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
            <iframe
              className="position-relative rounded w-100 h-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
              frameBorder={0}
              style={{ minHeight: 350, border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex={0}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default ContactUs;
