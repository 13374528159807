import React from "react";
const AboutUs = () => {
  return (
    <div
      className="container-fluid py-5 wow fadeInUp"
      data-wow-delay="0.1s"
      id="about"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">Why Us</h5>
              <h1 className="mb-0">
                Unique Self Assessment Accounting For Taxi Drivers
              </h1>
            </div>
            <div className="row g-0 mb-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3">
                  <i className="bi bi-check"></i>
                  Login From Anywhere
                </h5>
                <h5 className="mb-3">
                  <i className="bi bi-check"></i>
                  Estimated Tax Calculation
                  
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-3">
                  <i className="bi bi-check"></i>
                  Store Online Documentation
                </h5>
                <h5 className="mb-3">
                  <i className="bi bi-check"></i>
                  Certified by Charted Accountant
                </h5>
              </div>
            </div>
            <p className="mb-4">
              <ul>
                <li>
                  <b>Flexible From Anywhere:</b> Taxi accounting is a fully
                  flexible online system to control from anywhere
                </li>
                <li>
                  <b>Flexible Plus For Anyone:</b> It is so simple to use that
                  it could be suitable for anyone without any accounting
                  knowledge
                </li>
                <li>
                  <b>Premium For Certification:</b> Premium Account is manually
                  checked and certified by qualified Accountants
                </li>
              </ul>
            </p>
            <p>
              Taxi Accounting is a time-consuming system to reduce your time and
              afford to make your taxi business as simple as you understand
              After joining win this system, you are no longer. <br />
              waiting in the queue for Accountant appointments. You can see your
              Incomer Expenses and Financial Statements just log from anywhere,
              anytime. You can see your income, experts and financial statement
              just log in from anywhere, anytime <br />
              If you still think you need some advice from our dedicated
              accountants then you can make a Skype call or face to face
              appointment for your emergency.
            </p>
            <div
              className="d-flex align-items-center mb-4 wow fadeIn"
              data-wow-delay="0.6s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: 60, height: 60 }}
              >
                <i className="bi bi-telephone-fill text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call Us For Free Consultancy</h5>
                <h4 className="text-primary mb-0">01916918791</h4>
              </div>
            </div>
            {/* <a
              href="quote.html"
              className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
              data-wow-delay="0.9s"
            >
              Request A Quote
            </a> */}
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src="assets/img/about.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
