import React from "react";

const FeaturesList = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" id="feature">
      <div className="container py-5"  >
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Features</h5>
          
        </div>
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="row g-5">
              <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60 }}
                >
                 <i class="bi bi-person-bounding-box text-white"></i>
                </div>
                <h4>SELF-ASSESSMENT</h4>
                <p className="mb-0">
                  <small>
                    Self-Assessment is a big part of a taxi driver to declare as
                    a self-employed taxi driver with HMRC You just request to
                    HMRC for your UTR Number and permit us to deal it for your
                    taxi business and on the dedicated system will do rest.
                  </small>
                </p>
              </div>
              <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60 }}
                >
                  <i className="bi bi-award text-white"></i>
                </div>
                <h4>COST-EFFECTIVE</h4>
                <p className="mb-0">
                  <small>
                    It is a very cost-effective system compared to the
                    traditional accounting system. Our price is nearly half of
                    any other physical accountants. In the current market, taxi
                    drivers are charged £160 to £180 per tax year by any
                    certified accountants, on the other hand, we are charging
                    only £120 with a secure accounting system as well as a
                    certified Accountant. We also offer payments flexibility by
                    monthly instalments. Altogether it's an unbeatable price and
                    hard to find anywhere else.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4  wow zoomIn"
            data-wow-delay="0.9s"
            style={{ minHeight: 350 }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.1s"
                src="assets/img/feature.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row g-5">
              <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60 }}
                >
                  <i className="bi bi-box-seam text-white"></i>
                </div>
                <h4>LOCAL ACCOUNTANT</h4>
                <p className="mb-0">
                  <small>
                    Using our system you are not fully dependent on your
                    accountant, most of your calculations will do automated and
                    you will see your incomes, expenses, statements even
                    estimated tax still you think you need some advice for your
                    business or some guides in your crucial time then you can
                    make a Skype call to our dedicated Accountant on face to
                    face meeting with allocated Accountant.
                  </small>
                </p>
              </div>
              <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60 }}
                >
                  <i class="bi bi-file-earmark text-white"></i>
                </div>
                <h4>E-DOCUMENTS</h4>
                <p className="mb-0">
                  <small>
                    Now it's time for technology, In the last few years UK
                    government changed much paper works to online and they have
                    a plan to paperless e-governments within a few years.
                    Private companies also changing rapidly. Therefore PDF copy
                    and e-receipts are acceptable with HMRC. With our system,
                    you can copy and store all of your payment receipts for your
                    e-documentation.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeaturesList;
